<template>
    <v-card hover class="ma-0 pa-0">
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;" class="mb-2">
            <v-row no-gutters>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">{{ popupData.title }}</v-col>
                <v-col class="text-right mr-0 mt-0 pt-0">
                    <v-btn text @click="closePopup" color="white"><v-icon large>mdi-close</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
            <v-row no-gutters>
                <v-col>
                    <v-card-text style="padding:30px 30px 20px 30px; font: normal normal normal 20px/22px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;" v-html="popupData.message"></v-card-text>
                </v-col>
            </v-row>

            <v-row no-gutters v-if="popupData && popupData.sub_message">
                <v-col>
                    <v-card-text style="padding:0px 30px 20px 30px; font: normal normal normal 16px/18px Apple SD Gothic Neo; letter-spacing: -0.6px; color: #333333;" v-html="popupData.sub_message"></v-card-text>
                </v-col>
            </v-row>
        </v-card-text>

        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-8 pb-5 pt-0 text-right">
                    <v-btn class="ma-2" width="130px" elevation="0" color="#D3D8DD" outlined large v-on:click="closePopup()"><span
                        style="font: normal normal bold 18px/21px Apple SD Gothic Neo; color:#D3D8DD">{{ getCancelText() }}</span></v-btn>
                    <v-btn class="ma-2 white--text" width="130px" color="#4573D5" large v-on:click="okPopup()"><span style="font: normal normal bold 18px/21px Apple SD Gothic Neo;">{{
                            getAcceptText()
                        }}</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
    data: () => ({}),
    computed: {
        ...mapGetters({
            popupModal: 'popupModal',
            popupType: 'popupType',
            popupData: 'popupData',
        }),
    },
    mounted() {

    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
        okPopup() {
            this.$emit('ok')
        },
        getAcceptText() {
            let ret = '확인'
            if (this.popupData.accept_text) {
                ret = this.popupData.accept_text
            }
            return ret
        },
        getCancelText() {
            let ret = '취소'
            if (this.popupData.cancel_text) {
                ret = this.popupData.cancel_text
            }
            return ret
        }
    }
}
</script>
